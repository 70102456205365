import React from 'react'

import { I_CARD_PROPS } from './types'

import { Typography } from '@material-ui/core'
import { cardStyles } from './styles'


const Card: React.FC<I_CARD_PROPS> = ({
  title,
  text,
}) => {
  const classes = cardStyles()

  return (
    <div className={classes.root}>
      <div className={classes.icon}>
        <CheckIcon />
      </div>
      <div className={classes.title}>
        <Typography variant='subtitle1'>
          {title}
        </Typography>
      </div>
      <div>
        <Typography variant='body1'>
          {text}
        </Typography>
      </div>
    </div>
  )
}

export default Card


const CheckIcon = () => (
  <svg width='48' height='34' viewBox='0 0 48 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path fillRule='evenodd' clipRule='evenodd' d='M48 3.91712L18.0632 34L0 15.8487L3.89809 11.9315L18.0632 26.1658L44.1019 0L48 3.91712Z' fill='#FFD200'/>
  </svg>
)