import React, { useCallback } from 'react'

import { useRecoilState } from 'recoil'
import { errorsState, formState } from '../../../../state'

import {
  Grid,
  Box,
  TextField,
  FormControlLabel,
  FormHelperText,
} from '@material-ui/core'
import Checkbox from '../../../../components/ui/Checkbox'
import StepControl from '../../StepControl'
import useTranslate from '../../../../hooks/useTranslate'
import StepImage from '../../../../components/ui/StepImage'
import StepTitle from '../../../../components/ui/StepTitle'
import StepText from '../../../../components/ui/StepText'
import { I_STEP_PROPS } from '@/src/types/types'


const Step3: React.FC<I_STEP_PROPS> = ({
  data,
}) => {
  const [form, setForm] = useRecoilState(formState)
  const [errors] = useRecoilState(errorsState)
  const { title, text } = data
  const { t } = useTranslate()


  const handleChange = useCallback((e) => {
    const { name, value, type, checked } = e.target
    setForm(p => ({ ...p, [name]: type === 'checkbox' ? checked : value }))
  }, [])


  return (
    <Grid container alignItems='center'>
      <Grid item md={5} sm={12} xs={12}>
        <StepImage type='duck' />
      </Grid>

      <Grid item md={7} sm={12} xs={12}>
        <StepTitle>{title}</StepTitle>
        <StepText>{text}</StepText>
        <Box mb={1.5}>
          <TextField
            name='name'
            value={form.name}
            onChange={handleChange}
            error={!!errors.name}
            helperText={errors.name || ' '}
            variant='outlined'
            label={`${t('form_name')}*`}
            fullWidth
          />
        </Box>
        <Box mb={1.5}>
          <TextField
            name='site'
            value={form.site}
            onChange={handleChange}
            error={!!errors.site}
            helperText={errors.site || ' '}
            variant='outlined'
            label={`${t('form_site')}`}
            fullWidth
          />
        </Box>
        <Box mb={1.5}>
          <TextField
            name='email'
            value={form.email}
            onChange={handleChange}
            error={!!errors.email}
            helperText={errors.email || ' '}
            variant='outlined'
            label={`${t('form_email')}*`}
            fullWidth
          />
        </Box>
        <Box mb={2}>
          <TextField
            name='message'
            value={form.message}
            onChange={handleChange}
            error={!!errors.message}
            helperText={errors.message || ' '}
            variant='outlined'
            label={t('form_msg')}
            fullWidth
            multiline
            rows={3}
          />
        </Box>
        <Box mb={3}>
          <FormControlLabel
            control={<Checkbox checked={form.agree} onChange={handleChange} name='agree' />}
            label={`${t('form_agree')}*`}
          />
          <FormHelperText error>{errors.agree || ' '}</FormHelperText>
        </Box>

        {errors.submit ? (
          <Box mb={2}>
            <FormHelperText error>{errors.submit}</FormHelperText>
          </Box>
        ) : null}

        <StepControl
          layout={[
            { text: 'step_btn_send_msg', variant: 'contained', type: 'submit' },
          ]}
        />
      </Grid>
    </Grid>
  )
}

export default Step3