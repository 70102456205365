import React, { Fragment, useCallback } from 'react'

import { I_STEP_PROPS } from '@/src/types/types'

import StepControl from '../../StepControl'
import StepTitle from '../../../ui/StepTitle'
import StepText from '../../../ui/StepText'
import { Grid } from '@material-ui/core'
import StepImage from '../../../ui/StepImage'
import { useRecoilState } from 'recoil'
import { formState } from '../../../../state'


const Step2y2y0: React.FC<I_STEP_PROPS> = ({ data }) => {
  const [, setForm] = useRecoilState(formState)
  const {
    title,
    text,
    yes,
  } = data


  const handleYesClick = useCallback(() => {
    const questions = yes.reduce((acc, item) => {
      acc[item] = true
      return acc
    }, {})
    setForm(p => ({ ...p, questions }))
  }, [])


  return (
    <Fragment>
      <Grid container alignItems='center' spacing={2}>
        <Grid item xs={12} sm={5}>
          <StepImage type='duck' />
        </Grid>
        <Grid item xs={12} sm={7}>
          <StepTitle>{title}</StepTitle>
          <StepText>{text}</StepText>
          <div>
            <StepControl
              layout={[
                { text: 'step_btn_yes', href: '2.y.3', onClick: handleYesClick },
                { text: 'step_btn_no', href: '3' },
              ]}
            />
          </div>
        </Grid>
      </Grid>
    </Fragment>
  )
}

export default Step2y2y0