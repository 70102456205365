import React, { Fragment, useCallback } from 'react'

import { I_STEP_PROPS } from '../../../../types/types'

import StepControl from '../../StepControl'
import StepTitle from '../../../ui/StepTitle'
import StepText from '../../../ui/StepText'
import { Grid, Box, TextField } from '@material-ui/core'
import StepImage from '../../../ui/StepImage'
import { useRecoilState } from 'recoil'
import { formState } from '../../../../state'
import useTranslate from '../../../../hooks/useTranslate'


const Step2y0: React.FC<I_STEP_PROPS> = ({ data }) => {
  const [form, setForm] = useRecoilState(formState)
  const {
    title,
    text,
  } = data
  const { t } = useTranslate()

  const handleChange = useCallback((e) => {
    const { name, value } = e.target
    setForm(p => ({ ...p, [name]: value }))
  }, [])

  return (
    <Fragment>
      <Grid container alignItems='center' spacing={2}>
        <Grid item xs={12} sm={5}>
          <StepImage type='duck' />
        </Grid>
        <Grid item xs={12} sm={7}>
          <StepTitle>{title}</StepTitle>
          <StepText>{text}</StepText>
          <Box mb={6}>
            <TextField
              name='site'
              value={form.site}
              onChange={handleChange}
              variant='outlined'
              label={`${t('form_site')}`}
              placeholder='www.meineseite.de'
              fullWidth
            />
          </Box>
          <div>
            <StepControl
              layout={[
                { text: 'step_btn_forward', href: '2.y.1' },
              ]}
            />
          </div>
        </Grid>
      </Grid>
    </Fragment>
  )
}

export default Step2y0