import React, { Fragment } from 'react'

import { I_STEP_CONTROL_PROPS } from '@/src/types/types'

import { useRecoilState } from 'recoil'
import { formState } from '../../../state'
import useTranslate from '../../../hooks/useTranslate'
import Button from '@material-ui/core/Button'
import NextLink from 'next/link'
import { Grid } from '@material-ui/core'


const StepControl: React.FC<I_STEP_CONTROL_PROPS> = ({
  layout,
  justify,
}) => {
  const [form] = useRecoilState(formState)

  const { t } = useTranslate()

  return (
    <Grid container spacing={1} justify={justify}>
      {layout.map((item, index) => {
        const {
          text,
          href,
          variant,
          type,
          onClick,
        } = item
        const Wrapper = href ? NextLink : Fragment
        const wrapperProps: { href: string } = {
          href: '',
        }
        if (href) {
          wrapperProps.href = typeof href === 'function' ? href(form) : href
        }

        return (
          <Grid item key={index}>
            <Wrapper {...wrapperProps}>
              <Button
                type={type ? type : 'button'}
                variant={variant ? variant : 'outlined'}
                color='primary'
                onClick={() => onClick && onClick(item)}
              >
                {t(text)}
              </Button>
            </Wrapper>
          </Grid>
        )
      })}
    </Grid>
  )
}

export default StepControl