import React, { useCallback } from 'react'

import { I_STEP_PROPS } from '../../../../types/types'

import StepControl from '../../StepControl'
import StepTitle from '../../../ui/StepTitle'
import {
  Grid,
  Box,
  FormGroup,
  FormControlLabel,
} from '@material-ui/core'
import Checkbox from '../../../ui/Checkbox'
import StepImage from '../../../ui/StepImage'
import { useRecoilState } from 'recoil'
import { formState } from '../../../../state'


const Step2y2n0: React.FC<I_STEP_PROPS> = ({ data }) => {
  const [form, setForm] = useRecoilState(formState)

  const {
    title,
    questions = [],
  } = data


  const handleChange = useCallback((e) => {
    const { name, checked } = e.target
    setForm(p => ({ ...p, questions: { ...p.questions, [name]: checked } }))
  }, [])


  return (
    <Grid container alignItems='center' spacing={2}>
      <Grid item xs={12} sm={5}>
        <StepImage type='duck' />
      </Grid>
      <Grid item xs={12} sm={7}>
        <StepTitle>{title}</StepTitle>
        <Box mb={5}>
          {questions.map(({ label }, index) => (
            <FormGroup key={index}>
              <FormControlLabel
                control={<Checkbox checked={form.questions[label]} onChange={handleChange} name={label}/>}
                label={label}
              />
            </FormGroup>
          ))}
        </Box>
        <div>
          <StepControl
            layout={[
              {
                text: 'step_btn_forward',
                variant: 'contained',
                href: Object.keys(form.questions).some(x => x) ? '2.y.3' : '3',
              },
            ]}
          />
        </div>
      </Grid>
    </Grid>
  )
}

export default Step2y2n0