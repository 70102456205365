import React from 'react'

import { blurredMoweStyles } from './styles'

import NextImage from 'next/image'


const BlurredMowe: React.FC = ({
  children,
}) => {
  const classes = blurredMoweStyles()

  return (
    <div className={classes.root}>
      <div className={classes.duck}>
        <NextImage
          src={require('../../../../public/img/logo-large.png')}
          layout='fill'
          objectFit='contain'
          loading='eager'
          priority
        />
      </div>
      <div className={classes.children}>
        {children}
      </div>
    </div>
  )
}

export default BlurredMowe