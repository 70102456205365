import React, { Fragment } from 'react'

import { I_STEP_PROPS } from '../../../../types/types'

import StepControl from '../../StepControl'
import StepTitle from '../../../ui/StepTitle'
import { Grid, Box } from '@material-ui/core'
import { useRecoilState } from 'recoil'
import { formState } from '../../../../state'
import Card from '../../../../components/ui/Card'


const Step2y3: React.FC<I_STEP_PROPS> = ({ data }) => {
  const [form] = useRecoilState(formState)
  const { title, cards = [] } = data

  const { questions } = form

  return (
    <Fragment>
      <StepTitle>{title}</StepTitle>
      <Box mb={5}>
        <Grid container spacing={2}>
          {cards.map((card, index) => {
            const isChecked = !!questions[card.checkbox]
            if (!isChecked) { return null }

            return (
              <Grid
                item
                xl={3}
                lg={4}
                md={6}
                sm={12}
                key={index}
              >
                <Card
                  title={card.title}
                  text={card.text}
                />
              </Grid>
            )
          })}
        </Grid>
      </Box>
      <div>
        <StepControl
          layout={[
            { text: 'step_btn_what_to_start', variant: 'contained', href: '3' },
          ]}
        />
      </div>
    </Fragment>
  )
}

export default Step2y3