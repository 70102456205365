import React from 'react'

import {
  CheckboxProps,
  Checkbox as MuiCheckbox,
  SvgIcon,
} from '@material-ui/core'

const Checkbox: React.FC<CheckboxProps> = (props) => {
  return (
    <MuiCheckbox
      icon={<UncheckedIcon />}
      checkedIcon={<CheckedIcon />}
      color='primary'
      {...props}
    />
  )
}

export default Checkbox


const UncheckedIcon = () => (
  <SvgIcon viewBox='0 0 24 24'>
    <rect x='2' y='2' width='20' height='20' stroke='#FFD200' color='white' strokeWidth='2'/>
  </SvgIcon>
)

const CheckedIcon = () => (
  <SvgIcon viewBox='0 0 24 24'>
    <rect x='2' y='2' width='20' height='20' stroke='#FFD200' color='white' strokeWidth='2'/>
    <path fillRule='evenodd' clipRule='evenodd' d='M24 5.39151L9.08621 20.5385L0 11.3102L2.3547 8.91869L9.08621 15.7554L21.6453 3L24 5.39151Z' fill='black'/>
  </SvgIcon>
)