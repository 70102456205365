import { makeStyles } from '@material-ui/core'

export const cardStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'white',
    padding: '3rem 2.25rem',
    borderRadius: '1.25rem',
    boxShadow: '0 .25rem 4rem rgba(0, 0, 0, 0.06)',
  },
  icon: {
    textAlign: 'center',
    marginBottom: '1.5rem',
  },
  title: {
    marginBottom: '.75rem',
  },
}))