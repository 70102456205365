import { makeStyles } from '@material-ui/core'

export const blurredMoweStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    position: 'relative',
    minHeight: 'calc(100vh - 103px - 80px)',
    height: '100%',
  },
  duck: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundImage: `url(${require('../../../../public/img/logo-large.png')})`,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    animationName: '$duck',
    animationDuration: '3s',
    animationTimingFunction: theme.transitions.easing.easeInOut,
    animationFillMode: 'forwards',
    transformOrigin: '50% 64%',
    zIndex: -1,
  },
  '@keyframes duck': {
    '0%': {
      transform: 'scale(0)',
      opacity: 0,
    },
    '16%': {
      transform: 'scale(1) rotate(0deg)',
      opacity: 1,
      filter: 'blur(0)',
    },
    '88%': {
      transform: 'rotate(-16deg)',
    },
    '100%': {
      opacity: .64,
      transform: 'rotate(-15.5deg)',
      filter: 'blur(24px)',
    },
  },
  children: {
    position: 'relative',
    opacity: 0,
    animationName: '$children',
    animationDuration: '3s',
    animationDelay: '1.5s',
    animationTimingFunction: theme.transitions.easing.easeInOut,
    animationFillMode: 'forwards',
    zIndex: 2,
  },
  '@keyframes children': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
}))