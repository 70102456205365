import { I_CONFIG } from './types/types'
import {
  Step0,
  Step1,
  Step2n0,
  Step2y0,
  Step2y1,
  Step2y2n0,
  Step2y2y0,
  Step2y3,
  Step3,
  Step4,
} from './components/quiz/steps'


const config: I_CONFIG = {
  logoHref: '/step/0',
  stepComponents: {
    '0': Step0,
    '1': Step1,
    '2.y.0': Step2y0,
    '2.y.1': Step2y1,
    '2.y.2.n.0': Step2y2n0,
    '2.y.2.y.0': Step2y2y0,
    '2.y.3': Step2y3,
    '2.n.0': Step2n0,
    '3': Step3,
    '4': Step4,
  },
}

export default config