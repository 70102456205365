import React from 'react'

import { I_STEP_PROPS } from '@/src/types/types'

import BlurredMowe from '../../../ui/BlurredMowe'
import StepTitle from '../../../ui/StepTitle'
import StepText from '../../../ui/StepText'
import StepControl from '../../StepControl'


const Step0: React.FC<I_STEP_PROPS> = ({ data }) => {
  const {
    title,
    text,
  } = data

  return (
    <BlurredMowe>
      <StepTitle>{title}</StepTitle>
      <StepText>{text}</StepText>
      <StepControl
        layout={[
          { text: 'step_btn_interested', variant: 'contained', href: '1' },
        ]}
        justify='center'
      />
    </BlurredMowe>
  )
}

export default Step0
