import { atom, RecoilState, selector } from 'recoil'
import { T_FORM_ERRORS, T_FORM_VALUE } from '../types/types'


export const stepState = atom({
  key: 'stepState',
  default: '0',
})

export const stepOfStepsState = selector({
  key: 'stepOfStepsState',
  get: ({ get }) => {
    const step = get(stepState)

    return (t) => (
      `${step} ${t('step_of_steps')}`
    )
  },
})

export const formState: RecoilState<T_FORM_VALUE> = atom({
  key: 'formState',
  default: {
    questions: {},
    name: '',
    site: '',
    email: '',
    message: '',
    agree: false,
  },
})

export const errorsState: RecoilState<T_FORM_ERRORS> = atom({
  key: 'errorsState',
  default: {
    name: '',
    site: '',
    email: '',
    message: '',
    agree: '',
    submit: '',
  },
})