import React, { Fragment } from 'react'

import { I_STEP_PROPS } from '@/src/types/types'

import StepControl from '../../StepControl'
import StepTitle from '../../../ui/StepTitle'
import StepText from '../../../ui/StepText'
import { Grid } from '@material-ui/core'
import StepImage from '../../../ui/StepImage'


const Step1: React.FC<I_STEP_PROPS> = ({ data }) => {
  const {
    title,
    text,
  } = data

  return (
    <Fragment>
      <Grid container alignItems='center' spacing={2}>
        <Grid item xs={12} sm={5}>
          <StepImage type='duck' />
        </Grid>
        <Grid item xs={12} sm={7}>
          <StepTitle>{title}</StepTitle>
          <StepText>{text}</StepText>
          <div>
            <StepControl
              layout={[
                { text: 'step_btn_yes', href: '2.y.0' },
                { text: 'step_btn_no', href: '2.n.0' },
              ]}
            />
          </div>
        </Grid>
      </Grid>
    </Fragment>
  )
}

export default Step1